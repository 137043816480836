import { Resources } from "../resources/resources";

let alertedForIncompatibilty = false;

export const checkBrowserCompatibility = function (targetElement) {
    var supported = true;

    if (typeof window.HTMLElement === "undefined") {
        supported = false;
    }

    if (document.all && !document.querySelector) {
        supported = false;
    }

    var elem = document.createElement('canvas');
    if (!elem.getContext || !elem.getContext('2d')) {
        supported = false;
    }

    if (!window.XMLHttpRequest) {
        supported = false;
    }
    else {
        // try to initiate a CORS GET request to see if it's supported
        try {
            var http = new XMLHttpRequest();

            // the domain doesn't have to exist, we never actually send the request
            if (window.location.protocol != "https:") {
                http.open("GET", "http://fake.pathomation.com/", true);
            }
            else {
                http.open("GET", "https://fake.pathomation.com/", true);
            }
        } catch (e) {
            supported = false;
        }
    }

    if (!supported) {
        var element = null;
        if (targetElement) {
            if (targetElement.innerHTML !== undefined) {
                element = targetElement;
            }
            else if (typeof targetElement == "string") {
                // try to get by id if possible
                var elID = targetElement;
                elID = elID.replace('#', '');
                element = document.getElementById(elID);
            }
        }

        if (element !== null) {
            element.innerHTML = Resources.translate("Browser not supported.");
        }
        else if (!alertedForIncompatibilty) {
            alertedForIncompatibilty = true;
            if (typeof PMA.UI.IncompatibilityRedirect === "string") {
                document.location = PMA.UI.IncompatibilityRedirect;
            }
            else {
                alert(Resources.translate("Browser not supported."));
            }
        }
    }

    return supported;
};

/**
  * Converts an object to a URL encoded string of the form param1=value1&amp;param2=value2
  * @param {Object} data - The object to encode
  * @static
  * @return {string}
  * @ignore
  */
export const formify = function (data) {
    if (data) {
        var sData = "";

        var i = 0;
        for (var property in data) {
            if (data.hasOwnProperty(property)) {
                if (i > 0) {
                    sData += "&";
                }

                sData += encodeURIComponent(property) + "=" + encodeURIComponent(data[property]);
                i++;
            }
        }

        return sData;
    }

    return null;
};

/**
 * Ajax response callback. "this" will be the same with the one passed to PMA.UI.View.ajax
 * @callback AjaxCallback
 * @param {XMLHttpRequest} request - The object that was used to perform the ajax request
 */

/**
 * A function that will stringify the data.
 * @callback AjaxDataEncodeCallback
 * @param {Object} data - The payload
 * @return {string}
 */

/**
 * An object expected for drag and drop features
 * @typedef {Object} AjaxRequestOptions
 * @property {string} contentType - The request content type
 * @property {AjaxDataEncodeCallback} dataEncodeCallback - A function that will appropriately stringify the data. If null, the data will be formified.
 */

/**
 * Fires an HTTP ajax request. "this" is preserved and passed along to the callback
 * @param {string} url - The URL that the ajax request will be sent to
 * @param {string} method - The HTTP verb (POST, GET, PUT etc.)
 * @param {Object} data - The data to send. The method will properly encode the data automatically.
 * @param {AjaxCallback} callback - The function to call upon completion of the request
 * @param {AjaxRequestOptions} requestOptions - Optional HTTP request options
 * @static
 * @ignore
 */
const ajax = function (url, method, data, callback, requestOptions) {
    var _this = this;

    method = method.toUpperCase();
    if (!requestOptions) {
        requestOptions = {};
    }

    if (!requestOptions.dataEncodeCallback) {
        requestOptions.dataEncodeCallback = formify;
    }

    var sData = requestOptions.dataEncodeCallback(data);
    if (sData && method == "GET") {
        url = url + "?" + sData;
        sData = null;
    }

    var http = new XMLHttpRequest();

    http.open(method, url, true);

    if (requestOptions.contentType) {
        http.setRequestHeader("Content-type", requestOptions.contentType);
    }
    else if (method == "POST") {
        http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    }

    http.onreadystatechange = function () {
        if (http.readyState == 4 && typeof callback == "function") {
            callback.call(_this, http);
        }
    };

    if (sData) {
        http.send(sData);
    }
    else {
        http.send();
    }
};

export const guidGenerator = function () {
    var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

export { ajax };
