import ol_Feature from 'ol/Feature';
import ol_Collection from 'ol/Collection';
import * as ol_Extent from "ol/extent";
import ol_interaction_Pointer from 'ol/interaction/Pointer';
import ol_interaction_Translate from 'ol/interaction/Translate';
import { fromCircle } from 'ol/geom/Polygon';
import { Polygon, Circle as GeomCircle } from 'ol/geom';
import * as olFormat from 'ol/format';
import union from "@turf/union";
import difference from "@turf/difference";
import intersect from "@turf/intersect";
import booleanContains from "@turf/boolean-contains";

/** Brush interaction
 * @constructor
 * @extends {ol_interaction_Pointer}
 * @fires drawingstart | drawing | drawingend | erasingstart | erasing | erasingend
 * @param {any} options
 *  @param {ol.Map} options.map Map to use brush interaction on.
 *  @param {ol.Layer} options.layer Layer to use brush interaction on.
 *  @param {ol.Feature} [options.feature] Feature to use brush interaction on.
 *	@param {number} [options.brushSize=500] Brush size in projection coords distance, default 500.
 *	@param {BRUSHTYPES} [options.brushType=BRUSHTYPES.CIRCLE] Brush pointer type of BRUSHTYPES enum, default BRUSHTYPES.CIRCLE.
 *	@param {BRUSHMODES} [options.brushMode=BRUSHMODES.BRUSH] Interaction mode type of BRUSHMODES enum, default BRUSHMODES.BRUSH.
 */

var ol_interaction_Brush = function (options) {
    if (!options) options = {};
    var self = this;

    this.pointer_ = null;
    this.map_ = options.map || function () { return false; };
    this.sel_ = options.feature || null;
    this.feature_ = null;
    this.layer_ = options.layer || function () { return false; };
    this.idToDelete_ = null;
    this.translate_ = null;
    this.isDragging_ = false;

    ol_interaction_Pointer.call(this, {
        handleEvent: this.handleEvent_
    });

    this.set('brushSize', options.brushSize || 500);
    this.set('brushType', options.brushType || BRUSHTYPES.CIRCLE);
    this.set('brushMode', options.brushMode || BRUSHMODES.BRUSH);

    this.on('propertychange', function () {
    });
};

ol_interaction_Brush.prototype = Object.create(ol_interaction_Pointer.prototype);
ol_interaction_Brush.prototype.constructor = ol_interaction_Brush;

export const BRUSHTYPES = {
    CIRCLE: 'circle',
    SQUARE: 'square',
};

export const BRUSHMODES = {
    BRUSH: 'brush',
    ERASER: 'eraser',
};

/**
 * Activate/deactivate interaction.
 * @param {bool}
 * @api stable
 */
ol_interaction_Brush.prototype.setActive = function (b) {
    ol_interaction_Pointer.prototype.setActive.call(this, b);
};

/**
 * Changes size of brush pointer.
 * @param {number} brushSize Size of brush to set in projection coords distance.
 * @api stable
 */
ol_interaction_Brush.prototype.setBrushSize = function (brushSize) {
    if (brushSize == null || brushSize == undefined || brushSize <= 0) {
        return;
    }
    if (this.get('brushSize') == brushSize) {
        return;
    }
    this.set('brushSize', brushSize);
    this.setPointer_();
};

/**
 * Returns current size of brush pointer in projection coords distance.
 * @return {number} Current size of brush pointer in projection coords distance.
 * @api stable
 */
ol_interaction_Brush.prototype.getBrushSize = function () {
    return this.get('brushSize');
};

/**
 * Changes type of brush pointer.
 * @param {string} brushType Type of brush to set.
 * @api stable
 */
ol_interaction_Brush.prototype.setBrushType = function (brushType) {
    if (brushType == null || brushType == undefined) {
        return;
    }
    if (this.get('brushType') == brushType) {
        return;
    }
    this.set('brushType', brushType);
    this.setPointer_();
};

/**
 * Returns current type of brush pointer.
 * @return {string} Current type of brush pointer.
 * @api stable
 */
ol_interaction_Brush.prototype.getBrushType = function () {
    return BRUSHTYPES[this.get('brushType')];
};

/**
 * Changes type of brush pointer.
 * @param {string} brushType Type of brush to set.
 * @api stable
 */
ol_interaction_Brush.prototype.setBrushMode = function (brushMode) {
    if (brushMode == null || brushMode == undefined) {
        return;
    }
    if (this.get('brushMode') == brushMode) {
        return;
    }
    this.set('brushMode', brushMode);
    this.setPointer_();
};

/**
 * Returns current mode of brush interaction.
 * @return {string} Current mode of brush interaction.
 * @api stable
 */
ol_interaction_Brush.prototype.getBrushMode = function () {
    return this.get('brushMode');
};

/**
 * @private
 */
ol_interaction_Brush.prototype.setPointer_ = function () {
    if (this.format_ == null || this.format_ == undefined) {
        this.format_ = new olFormat.GeoJSON();
    }
    if (this.formatOptions_ == null || this.formatOptions_ == undefined) {
        this.formatOptions_ = { featureProjection: this.map_.getView().getProjection(), };
    }
    var pointerGeom = null;
    if (this.get('brushType') == BRUSHTYPES.CIRCLE) {
        pointerGeom = fromCircle(new GeomCircle([0, 0], this.get('brushSize')));
    }
    if (this.get('brushType') == BRUSHTYPES.SQUARE) {
        var size = this.get('brushSize');
        pointerGeom = new Polygon([
            [
                [size * -1, size],
                [size, size],
                [size, size * -1],
                [size * -1, size * -1],
                [size * -1, size],
            ],
        ]);
    }
    var pointer = new ol_Feature({
        geometry: pointerGeom,
    });

    pointer.color = '#000';
    pointer.fillColor = '#00000099';
    pointer.penSize = 1;

    if (this.pointer_ != null) {
        this.layer_.getSource().removeFeature(this.pointer_);
        this.map_.removeInteraction(this.translate_);
    }

    this.pointer_ = pointer;
    this.map_.getViewport().style.cursor = "none";

    this.translate_ = new ol_interaction_Translate({
        features: new ol_Collection([this.pointer_])
    });

    this.layer_.getSource().addFeature(this.pointer_);
    this.map_.addInteraction(this.translate_);
};

/**
 * @param {ol.MapBrowserEvent} evt Map browser event.
 * @private
 */
ol_interaction_Brush.prototype.handleEvent_ = function (evt) {
    switch (evt.type) {
        case "pointerdown":
            this.handleDownEvent_(evt);
            break;
        case "pointermove":
            this.handleMoveEvent_(evt);
            break;
        case "pointerdrag":
            if (!this.isDragging_) {
                this.handleDownEvent_(evt);
            } else {
                this.handleDragEvent_(evt);
            }
            break;
        case "pointerup":
            this.handleUpEvent_(evt);
            break;
        default:
            return true;
    }
}

/**
 * @param {ol.MapBrowserEvent} evt Map browser event.
 * @private
 */
ol_interaction_Brush.prototype.handleDownEvent_ = function (evt) {
    try {
        var feature = this.getFeatureAtPixel_(evt.pixel).feature;
        if (feature) {
            this.isDragging_ = true;
            this.feature_ = feature;
        } else {
            this.isDragging_ = false;
            this.feature_ = null;
        }
    }
    catch
    {
        this.isDragging_ = false;
        this.feature_ = null;
    }
    if (this.get('brushMode') == BRUSHMODES.BRUSH) {
        this.dispatchEvent("drawingstart");
    }
    if (this.get('brushMode') == BRUSHMODES.ERASER) {
        this.dispatchEvent("erasingstart");
    }
};

/**
 * @param {ol.MapBrowserEvent} evt Map browser event.
 * @private
 */
ol_interaction_Brush.prototype.handleDragEvent_ = function (evt) {
    evt.preventDefault();
    var feature = null;
    if (this.sel_) {
        feature = this.sel_;
    } else {
        feature = this.feature_;
    }
    if (feature == null || feature == undefined) {
        return;
    }
    var format = this.format_;
    var formatOptions = this.formatOptions_;
    var currentBrushFeature = this.pointer_;

    if (feature.metaData) {
        var metaNotes = feature.metaData.Notes ? feature.metaData.Notes : "";
        var metaColor = feature.metaData.Color ? feature.metaData.Color : feature.color;
        var metaFillColor = feature.metaData.FillColor ? feature.metaData.FillColor : feature.fillColor;
        var metaPenSize = feature.metaData.LineThickness ? feature.metaData.LineThickness : feature.penSize;
    }
    var metaIcon = feature.icon || null;

    if (this.get('brushMode') == BRUSHMODES.BRUSH) {
        if (feature.getGeometry().getType() !== "Polygon" && feature.getGeometry().getType() !== "MultiPolygon") {
            console.error("Feature contains non-polygon geometry");
            return;
        }
    }
    if (this.get('brushMode') == BRUSHMODES.ERASER) {
        if (feature.getGeometry().getType() !== "Polygon") {
            console.error("Feature contains non-polygon geometry");
            return;
        }
    }
    var inputPolygonFeatureJson = format.writeFeatureObject(feature, formatOptions);
    var pointerFeatureJson = format.writeFeatureObject(currentBrushFeature, formatOptions);
    var outputFeatureJson = null;
    if (this.get('brushMode') == BRUSHMODES.BRUSH) {
        try {
            var intersection = intersect(inputPolygonFeatureJson, pointerFeatureJson);
            if (!intersection) {
                return;
            }
        } catch {
            console.error("Intersection failed! Geometry is broken. Fixing...");
        }
        outputFeatureJson = this.unify_(inputPolygonFeatureJson, pointerFeatureJson);
    }
    if (this.get('brushMode') == BRUSHMODES.ERASER) {
        if (booleanContains(pointerFeatureJson, inputPolygonFeatureJson)) {
            this.idToDelete_ = inputPolygonFeatureJson.id;
            this.feature_ = null;
            this.dispatchEvent("erasing");
            return;
        } else {
            try {
                var intersection = intersect(inputPolygonFeatureJson, pointerFeatureJson);
                if (!intersection) {
                    return;
                }
            } catch {
                console.error("Intersection failed! Geometry is broken. Fixing...");
                outputFeatureJson = this.unify_(inputPolygonFeatureJson, pointerFeatureJson);
            }
            // Feature holes not allowed
            if (booleanContains(inputPolygonFeatureJson, pointerFeatureJson)) {
                return;
            }
            outputFeatureJson = this.differentiate_(inputPolygonFeatureJson, pointerFeatureJson);
        }
    }
    if (outputFeatureJson == null) {
        return;
    }
    if (outputFeatureJson.geometry.type === "MultiPolygon") {
        outputFeatureJson.geometry.coordinates.forEach((coords) => {
            var feat = { 'type': 'Polygon', 'coordinates': coords };

            var newFeature = format.readFeature(feat, formatOptions);

            newFeature.color = metaColor;
            newFeature.fillColor = metaFillColor;
            newFeature.penSize = metaPenSize;
            newFeature.icon = metaIcon;
            newFeature.notes = metaNotes ? metaNotes : "";

            this.idToDelete_ = inputPolygonFeatureJson.id;
            this.feature_ = newFeature;
            if (this.get('brushMode') == BRUSHMODES.BRUSH) {
                this.dispatchEvent("drawing");
            }
            if (this.get('brushMode') == BRUSHMODES.ERASER) {
                this.dispatchEvent("erasing");
            }
            // if (this.sel_) {
            //     this.stopEditing();
            // }
        });
    } else {
        var newFeature = format.readFeature(outputFeatureJson, formatOptions);
        feature.setGeometry(newFeature.getGeometry());
        feature.changed();
        this.idToDelete_ = null;
        this.feature_ = feature;
        if (this.get('brushMode') == BRUSHMODES.BRUSH) {
            this.dispatchEvent("drawing");
        }
        if (this.get('brushMode') == BRUSHMODES.ERASER) {
            this.dispatchEvent("erasing");
        }
    }
};

/**
 * @param {ol.MapBrowserEvent} evt Map browser event.
 * @private
 */
ol_interaction_Brush.prototype.handleMoveEvent_ = function (evt) {
    if (this.map_ == null) {
        this.map_ = evt.map;
    }
    if (this.pointer_ == null) {
        this.setPointer_();
    }
    // if (evt.dragging) {
    //     this.handleDragEvent_(evt);
    // }
    var extent = this.pointer_.getGeometry().getExtent();
    var center = ol_Extent.getCenter(extent);
    this.pointer_.getGeometry().translate(evt.coordinate[0] - center[0], evt.coordinate[1] - center[1]);
};

/**
 * @param {ol.MapBrowserEvent} evt Map browser event.
 * @private
 */
ol_interaction_Brush.prototype.handleUpEvent_ = function (evt) {
    this.isDragging_ = false;
    this.feature_ = null;
    if (this.get('brushMode') == BRUSHMODES.BRUSH) {
        this.dispatchEvent("drawingend");
    }
    if (this.get('brushMode') == BRUSHMODES.ERASER) {
        this.dispatchEvent("erasingend");
    }
};

/**
 * @param {ol.GeoJSON} input GeoJSON object of current feature.
 * @param {ol.GeoJSON} pointer GeoJSON object of current pointer.
 * @return {ol.GeoJSON || null} GeoJSON object of unified geometry.
 * @private
 */
ol_interaction_Brush.prototype.unify_ = function (input, pointer) {
    var unifiedFeatureJson = union(input, pointer);
    if (unifiedFeatureJson.type === "FeatureCollection") {
        throw "Feature collection detected";
    }

    if (unifiedFeatureJson.geometry.type === "MultiPolygon") {
        return null;
    } else {
        return unifiedFeatureJson;
    }
};

/**
 * @param {ol.GeoJSON} input GeoJSON object of current feature.
 * @param {ol.GeoJSON} pointer GeoJSON object of current pointer.
 * @return {?ol.GeoJSON} GeoJSON object of differentiated geometry.
 * @private
 */
ol_interaction_Brush.prototype.differentiate_ = function (input, pointer) {
    var differentiatedFeatureJson = difference(input, pointer);
    if (!differentiatedFeatureJson) {
        return null;
    }
    if (differentiatedFeatureJson.type === "FeatureCollection") {
        throw "Feature collection detected";
    }
    return differentiatedFeatureJson;
};

/**
 * @param {ol.MapBrowserEvent.pixel} pixel Pixel coords of pointer.
 * @return {?ol.Feature} Feature found under pointer location.
 * @private
 */
ol_interaction_Brush.prototype.getFeatureAtPixel_ = function (pixel) {
    var self = this;
    var size = this.get('brushSize');
    var geometry = new Polygon([
        [
            [size * -1, size],
            [size, size],
            [size, size * -1],
            [size * -1, size * -1],
            [size * -1, size],
        ],
    ]);
    var coordinates = geometry.getCoordinates();
    var pixels0 = this.map_.getPixelFromCoordinate(coordinates[0][0]);
    var pixels1 = this.map_.getPixelFromCoordinate(coordinates[0][1]);
    var tol = (pixels1[0] - pixels0[0]) / 2;
    return this.map_.forEachFeatureAtPixel(pixel,
        function (feature, layer) {
            if (self.layer_ === layer && feature !== self.pointer_) {
                return { feature: feature };
            } else {
                return null;
            }
        },
        { hitTolerance: tol }
    ) || null;
};

/**
 * Returns current feature.
 * @return {ol.Feature} Current feature.
 * @api stable
 */
ol_interaction_Brush.prototype.getFeature = function () {
    return this.feature_;
};

/**
 * Returns ID of feature to remove.
 * @return {string | number} ID of feature to remove.
 * @api stable
 */
ol_interaction_Brush.prototype.getId = function () {
    return this.idToDelete_;
};

/**
 * Disables and removes interaction.
 * @api stable
 */
ol_interaction_Brush.prototype.stopEditing = function () {
    if (this.map_) {
        this.map_.getViewport().style.cursor = "unset";
        this.map_.removeInteraction(this.translate_);
        this.map_.removeInteraction(this);
    }

    if (this.layer_) {
        this.layer_.getSource().removeFeature(this.pointer_);
    }

    ol_interaction_Pointer.prototype.setActive.call(this, false);
};

export default ol_interaction_Brush;