import { getVersion } from './view/version';
import { Resources } from './resources/resources';
import { Viewport } from './view/viewport';
import { Themes, ButtonLocations, ObjectiveResolutions, AnnotationState, Controls as ControlTypes, AnnotationTools } from './view/definitions';
import { Tree } from './components/js/tree';
import { Context } from './components/js/context';
import { AutoLogin } from './components/js/autologin';
import { PromptLogin } from './components/js/promptlogin';
import { SessionLogin } from './components/js/sessionlogin';
import { CustomLogin } from './components/js/customlogin';
import { Gallery } from './components/js/gallery';
import {
    Events as ComponentEvents,
    GalleryRenderOptions,
    GetSlidesScope,
    getThumbnailUrl,
    getBarcodeUrl,
    getSnapshotUrl,
    parseDragData,
    login,
    formify,
    ajax,
    callApiMethod,
    DragDropMimeType
} from './components/js/components';
import './view/view.css';
import 'ol/ol.css';
import './view/ol/ol.css';
import './components/css/components.css';
import { Annotations } from './components/js/annotations';
import { SlideLoader } from './components/js/slideLoader';
import { Forms } from './components/js/forms';
import { MetadataTree } from './components/js/metadatatree';
import { MetadataSearch } from './components/js/metaDataSearch';
import { SyncView } from './components/js/syncview';
import { Events as ViewerEvents, Annotation as AnnotationTypes } from './view/definitions';
import { RotationControl } from './view/controls/rotationControl';
import { Overview } from './view/controls/overview';
import { Filename } from './view/controls/filename';
import { PrevZoom } from './view/controls/prevZoom';
import { DimensionSelector } from './view/controls/dimensionSelector';
import { Snapshot } from './view/controls/snapshot';
import { AssociatedImage } from './view/controls/associatedImage';
import { PathomationAttribution } from './view/controls/pathomationAttribution';
import { ColorAdjustment } from './view/controls/colorAdjustment';
import { LayerSwitch } from './view/controls/layerSwitch';
import { Magnifier } from './view/controls/magnifier';
import { unByKey } from 'ol/Observable';
import { Feature } from 'ol';
import { Point, Polygon } from 'ol/geom';
import { Style, Icon, Text, Fill, Stroke } from 'ol/style';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { ApiMethods } from './components/js/components';
import { MouseWheelZoomMode } from './view/interactions/customMouseWheelZoom';

export const
    /**
     * PMA.UI namespace
     * @namespace
     * @memberof PMA
     */
    UI = {
        getVersion: getVersion,
        Resources: Resources,
        View: {
            Viewport: Viewport,
            Themes: Themes,
            ButtonLocations: ButtonLocations,
            Events: ViewerEvents,
            ObjectiveResolutions: ObjectiveResolutions,
            getThumbnailUrl: getThumbnailUrl,
            getBarcodeUrl: getBarcodeUrl,
            Controls: {
                RotationControl: RotationControl,
                Overview: Overview,
                Filename: Filename,
                PrevZoom: PrevZoom,
                DimensionSelector: DimensionSelector,
                AssociatedImage: AssociatedImage,
                Snapshot: Snapshot,
                PathomationAttribution: PathomationAttribution,
                ColorAdjustment: ColorAdjustment,
                LayerSwitch: LayerSwitch,
                Magnifier: Magnifier
            }
        },
        Types: {
            Controls: ControlTypes,
            Annotation: AnnotationTypes,
            AnnotationState: AnnotationState,
            MouseWheelZoomMode: MouseWheelZoomMode,
            AnnotationTools: AnnotationTools,
        },
        Components: {
            Tree: Tree,
            Context: Context,
            Events: ComponentEvents,
            Annotations: Annotations,
            AnnotationState: AnnotationState,
            GalleryRenderOptions: GalleryRenderOptions,
            GetSlidesScope: GetSlidesScope,
            Gallery: Gallery,
            SlideLoader: SlideLoader,
            Forms: Forms,
            MetadataTree: MetadataTree,
            MetadataSearch: MetadataSearch,
            SyncView: SyncView,
            ApiMethods: ApiMethods,
            getThumbnailUrl: getThumbnailUrl,
            login: login,
            getBarcodeUrl: getBarcodeUrl,
            getSnapshotUrl: getSnapshotUrl,
            parseDragData: parseDragData,
            formify: formify,
            ajax: ajax,
            callApiMethod: callApiMethod,
            DragDropMimeType: DragDropMimeType
        },
        Authentication: {
            AutoLogin: AutoLogin,
            PromptLogin: PromptLogin,
            SessionLogin: SessionLogin,
            CustomLogin: CustomLogin,
        },
        OpenLayers: {
            Observable: {
                unByKey: unByKey
            },
            Feature: Feature,
            Geom: {
                Point: Point,
                Polygon: Polygon,
            },
            Style: {
                Style: Style,
                Icon: Icon,
                Text: Text,
                Fill: Fill,
                Stroke: Stroke
            },
            Source: {
                Vector: VectorSource
            },
            Layer: {
                Vector: VectorLayer
            }
        }
    };

